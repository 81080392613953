import React from "react";
import {connect} from 'react-redux';
import GenresList from "./Books/GenresList";
import {fetchGenres} from "../actions";
import {notUndifine} from "../utils";
import GenresHeader from "./Books/GenreHeader";

class Genres extends React.Component {
  componentDidMount() {
    this.props.fetchGenres();
  }

  render() {
    return (
      <React.Fragment>
        <GenresHeader />
        <div className="bg-light">
          <div className="container my-3">
            <GenresList genres={this.props.genres} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateTopProps = (state) => {
  let genres = [];
  if (notUndifine(state.books.genres)) {
    genres = state.books.genres;
  }
  return {
    genres
  };
}

export default connect(mapStateTopProps, {fetchGenres})(Genres);