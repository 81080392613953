import React from "react";
import BookHistory from "./BookHistory";

const HistoryList = ({histories, handleDelete}) => {

  const loadHistoryList = (histories, handleDelete) => {
    return (
      histories.map(book => {
        return (
          <BookHistory
            key={book.bookTitle}
            id={book.bookId}
            title={book.bookTitle}
            url={book.bookUrl}
            chapter={book.sectionTitle}
            currentTime={book.currentTime}
            duration={book.duration}
            deleteAction={() => handleDelete(book.bookId)}
          />
        );
      })
    );
  }

  return (
    <div
      className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 justify-content-center justify-content-xl-start">
      {loadHistoryList(histories, handleDelete)}
    </div>
  );
}

export default HistoryList;