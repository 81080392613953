import React from "react";
import GoogleAuth from "../GoogleAuth";

class AdminLogin extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="py-1 mt-5 text-center container">
          <div className="row mt-3 border-bottom">
            <div className="col mx-auto">
              <h1 className="fw-light">Login
              </h1>
            </div>
          </div>
        </section>
        <div className="bg-light">
          <div className="container my-3">
            <GoogleAuth />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AdminLogin;