import React from "react";

const Source = () => {
  return (
    <React.Fragment>
      <section className="py-1 mt-5 text-center container">
        <div className="row mt-3 border-bottom">
          <div className="col mx-auto">
            <h1 className="fw-light">Sources
            </h1>
          </div>
        </div>
      </section>
      <div className="bg-light">
        <div className="container my-3">
          <p>
            YouBookReader public domain audiobooks are attributed to Librivox.org. We gratefully cherish the effort of
            the devoted volunteers who recorded the public domain books and posted them to Librivox. As a part of the
            book-lover world, it is YouBookReader's responsibility to reorganize the sources into a more engaging
            appearance and deliver them to the wide public.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Source;