import React from 'react'
import { connect } from 'react-redux';
import {fetchBook, fetchAudios, fetchAuthors, setViewed, fetchBookGenre} from "../actions";
import TitleDetail from "./Books/TitleDetail";
import PlayingDetail from "./Books/PlayingDetail";
import Summary from "./Books/Summary";
import CategoryList from "./Books/CategoryList";
import BookGenreList from "./Books/BookGenreList";

class Detail extends React.Component {

  componentDidMount() {
    this.props.fetchBook(this.props.match.params.id);
    this.props.fetchAudios(this.props.match.params.id);
    this.props.fetchAuthors(this.props.match.params.id);
    this.props.fetchBookGenre(this.props.match.params.id)
    this.props.setViewed(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.book.title !== prevProps.book.title) {
      document.title = this.props.book.title;
    }
  }

  render() {
    if (!this.props.book) {
      return <div>Loading...</div>
    }
    return (
      <React.Fragment>
        <CategoryList/>
        <TitleDetail
          title={this.props.book.title}
          authors={this.props.authors}
        />
        <main>
          <div className="bg-light">
            <div className="container my-3">
              <BookGenreList
                bookgenres={this.props.bookgenres}
              />
              <Summary
                description={this.props.book.description}
              />
              <PlayingDetail
                bookId={this.props.match.params.id}
                bookTitle={this.props.book.title}
                bookUrl={this.props.book.url}
                duration={this.props.book.total_time}
                rating={this.props.book.rating}
              />
            </div>
          </div>
        </main>
      </React.Fragment>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let book = {};
  if (state.books.detail) {
    book = state.books.detail;
  }
  const authors = state.books.authors;
  let bookgenres = [];
  if (state.books.bookgenres) {
    bookgenres = state.books.bookgenres
  }
  console.log(bookgenres);
  return {
    book: book,
    authors,
    audios: Object.values(state.audios),
    bookgenres
  };
}

export default connect(mapStateToProps, { fetchBook, fetchAudios, fetchAuthors, fetchBookGenre, setViewed } )(Detail);