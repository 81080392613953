export const convertToTime = (time) => {
  if (!isNaN(time)) {
    return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
  }
}

export const getPage = (nextUrl) => {
  const url = new URL(nextUrl);
  const urlParam = new URLSearchParams(url.search);
  let newPage = 1;
  if (urlParam.get('page')) {
    newPage = parseInt(urlParam.get('page'));
  }
  return newPage;
}

export const notUndifine = (value) => {
  if (typeof value !== "undefined" && value != null) {
    return true;
  }
  return false
}

export const getOneYearInSecond = () => {
  return 60 * 60 * 24 * 365;
}

export const getOneYearDate = () => {
  const d = new Date();
  d.setTime(d.getTime() + getOneYearInSecond() * 1000);
  return d;
}

export const replace = (value) => {
  return value.replaceAll(' ', '_');
}

