import React from 'react';
import { Field, reduxForm } from "redux-form";
import StarRatings from "react-star-ratings";

class ReviewForm extends React.Component {

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
        <div className="form-group">
          <label htmlFor="ratingSelect">My rating</label>
          <Field id="ratingSelect" name="rating" value={5} component={props =>
            <StarRatings
              starDimension="20px"
              starSpacing="1px"
              starRatedColor="yellow"
              rating={props.input.value}
              changeRating={(param) => {
                props.input.onChange(param)

              }
              } label="Enter label"/>
          } />
        </div>
        <div className="form-group">
          <label htmlFor="commentText">Example textarea</label>
          <Field name="comment" className="form-control" id="commentText" rows="3" component="textarea" />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'streamForm'
})(ReviewForm);