import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import bookReducer from "./bookReducer";
import authReducer from "./authReducer";
import audioReducer from "./audioReducer";
import audioSelectedReducer from "./audioSelectedReducer";
import historyReducer from "./historyReducer";
import sortReducer from "./sortReducer";

export default combineReducers({
  form: formReducer,
  books: bookReducer,
  auth: authReducer,
  audios: audioReducer,
  audioSelected: audioSelectedReducer,
  history: historyReducer,
  sort: sortReducer

});