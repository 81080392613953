import React from "react";
import {Link} from "react-router-dom";
import {replace} from "../../utils";

const GenreItem = ({id, name, count}) => {
  const nameUrl = replace(name);
  return (
    <Link to={`/category/${id}/${nameUrl}`} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
      {name}
      <span className="badge badge-primary rounded-pill">{count}</span>
    </Link>
  );
}

export default GenreItem;