import React from 'react';
import StarRatings from "react-star-ratings";
import './BookItem.css';
import {Link} from "react-router-dom";
import Image from "./Image";
import BookGenreList from "./BookGenreList";
import BookEditButton from "../Admin/BookEditButton";
import {replace} from "../../utils";

const BookItem = ({id, title, author, rating, url, duration}) => {
  const titleUrl = replace(title);
  return (
    <div className="card_item my-3 mx-1 shadow bg-white">
      <div className="card border-0">
        <div className="card-header text-center border-0"><Link to={`/detail/${id}/${titleUrl}`}><Image src={url} className="shadow w-100"/></Link>
        </div>
        <div className="card-body">
          <p className="card-text"><Link to={`/detail/${id}/${titleUrl}`}>{title}</Link></p>
          <p className="card-text">{author}</p>
          <div className="d-flex justify-content-between align-items-center">
            <StarRatings
              rating={rating}
              starDimension="17px"
              starSpacing="0px"
              starRatedColor="#FDCC0D"
            />
            <small className="mx-1 text-muted">Duration: {duration}</small>
          </div>
          <BookEditButton bookId={id} />
        </div>
      </div>
    </div>
  );
}

export default BookItem;