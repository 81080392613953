import React from 'react';
import {connect} from 'react-redux';
import {withCookies} from 'react-cookie';
import history from "../../history";
import {
  fetchAudios,
  selectAudio,
  updatePlayAction,
  updatePlaySpeed,
  updatePosition,
  resetAudioPlayer, fetchHistory
} from "../../actions";
import {
  PLAYING_ACTION_BACK,
  PLAYING_ACTION_NEXT,
  PLAYING_ACTION_PLAY, PLAYING_ACTION_REWIND,
  PLAYING_ACTION_STOP,
  PLAYING_ACTION_WIND
} from "../../actions/type";
import _ from "lodash";
import StarRatings from "react-star-ratings";
import Image from "./Image";
import {convertToTime, getOneYearDate, getOneYearInSecond, notUndifine} from "../../utils";

class PlayingDetail extends React.Component {


  componentDidMount() {
    this.unblock = history.block(targetLocation => {
      this.bookmark();
      return true;
    });

    this.seekControl.value = 0;
    this.showCurrentPlayTime(0,0)
    this.player.addEventListener("timeupdate", e => {
      this.showCurrentPlayTime(e.target.currentTime,e.target.duration)
      if (this.seekControl) {
        this.seekControl.min = 0;
        this.seekControl.max = e.target.duration;
        this.seekControl.value = e.target.currentTime;
      }
      if (e.target.currentTime > 0 && e.target.duration > 0 && (e.target.currentTime === e.target.duration)) {
        this.props.updatePlayAction(PLAYING_ACTION_NEXT);
      }
    });
    this.updatePlayButton();
    this.loadBookmark(this.props.bookId);
  }

  componentWillUnmount() {
    this.unblock();
    this.player.removeEventListener("timeupdate", () => {
    });
    this.player.remove();
    this.props.resetAudioPlayer();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let track;
    if (this.props.selectedListenUrl !== prevProps.selectedListenUrl) {
      track = this.props.selectedListenUrl;
      if (track) {
        this.player.src = track;

        if (!isNaN(this.props.selectedSpeed)) {
          this.player.playbackRate = this.props.selectedSpeed;
        }
        //this.player.play();
        //this.props.updatePlayAction(PLAYING_ACTION_PLAY)
      }
    }
    if (this.props.actionCallAt !== prevProps.actionCallAt) {
      if (this.props.selectedAction === PLAYING_ACTION_PLAY) {
        console.log('play');
        if (!isNaN(this.props.selectedSpeed)) {
          this.player.playbackRate = this.props.selectedSpeed;
        }
        if (isNaN(this.props.selectedIndex) && this.props.audios.length > 0) {
          const audio = this.props.audios[0];
          this.props.selectAudio(audio.section_number, audio.listen_url, 0);
          // play in the next cicle
          this.props.updatePlayAction(PLAYING_ACTION_PLAY);
        }
        else {
          // play because the are a selected video
          this.player.play();
          // update play button icon
          this.updatePlayButton();
        }

      }
      if (this.props.selectedAction === PLAYING_ACTION_STOP) {
        console.log('pause');
        this.player.pause();
        // update play button icon
        this.updatePlayButton();
      }
      if (this.props.selectedAction === PLAYING_ACTION_NEXT) {
        //this.player.pause();
        let index = this.props.selectedIndex;
        index++;
        if (index <= this.props.audios.length - 1) {
          // play the next video
          const audio = this.props.audios[index];
          this.props.selectAudio(audio.section_number, audio.listen_url, index);
          this.props.updatePlayAction(PLAYING_ACTION_PLAY)
        } else {
          // finish the book
          this.props.updatePlayAction(PLAYING_ACTION_STOP)
        }
      }
      if (this.props.selectedAction === PLAYING_ACTION_BACK) {
        //this.player.pause();
        let index = this.props.selectedIndex;
        index--;
        if (index < 0) {
          index = this.props.audios.length - 1;
        }
        const audio = this.props.audios[index];
        this.props.selectAudio(audio.section_number, audio.listen_url, index);
        this.props.updatePlayAction(PLAYING_ACTION_PLAY);

      }
      if (this.props.selectedAction === PLAYING_ACTION_WIND) {
        if (this.player.currentTime + 10 < this.player.duration) {
          this.player.currentTime = this.player.currentTime + 10;
        }
      }
      if (this.props.selectedAction === PLAYING_ACTION_REWIND) {
        if (this.player.currentTime - 10 > 0) {
          this.player.currentTime = this.player.currentTime - 10;
        }
      }
    }
    if (this.props.selectedSpeed !== prevProps.selectedSpeed) {
      this.player.playbackRate = this.props.selectedSpeed;
    }

    if (this.props.selectedPosition !== prevProps.selectedPosition) {
      this.player.currentTime = this.props.selectedPosition;
    }

  }
  showSelectedTitle() {
    if (!isNaN(this.props.selectedIndex)) {
      return this.props.audios[this.props.selectedIndex].title;
    }
  }

  showPlayingSpeed() {
    if (isNaN(this.props.selectedSpeed)) {
      return 'speed: 1x'
    } else {
      return 'speed: ' + this.props.selectedSpeed + 'x';
    }
  }

  showCurrentPlayTime(current, duration) {
    if (this.currentTime && this.duration && this.currentTimeValue && this.durationValue) {
      this.currentTime.innerText = convertToTime(current);
      this.duration.innerText = convertToTime(duration);
      this.durationValue.value = duration;
      this.currentTimeValue.value = current
    }
  }

  showCurrentSection() {
    if (this.props.audios && this.props.audios.length > this.props.selectedIndex) {
      return this.props.audios[this.props.selectedIndex].title;
    }
    return null;
  }

  speedUp() {
    let speed = this.props.selectedSpeed;
    if (!speed) {
      speed = 1;
    }
    speed += 0.25;
    this.props.updatePlaySpeed(speed);
  }

  speedDown() {
    let speed = this.props.selectedSpeed;
    if (!speed) {
      speed = 1;
    }
    speed -= 0.25;
    this.props.updatePlaySpeed(speed);
  }

  speedNormal() {
    this.props.updatePlaySpeed(1);
  }

  handleCurrentTimeChange(e) {
    this.props.updatePosition(e.target.value);

  }

  handleVolumeChange(e) {
    this.player.volume = e.target.value;
  }

  playOrPause() {
    if (this.player.paused) {
      this.props.updatePlayAction(PLAYING_ACTION_PLAY);
    } else {
      this.props.updatePlayAction(PLAYING_ACTION_STOP)
    }
  }

  updatePlayButton() {
    console.log('show play button');
    if (this.player && this.player.paused) {
      this.playIcon.className = "fa fa-play";
    } else {
      this.playIcon.className = "fa fa-pause";
    }
  }


  bookmark() {
    // get from the cookie
    let visitedBooks = this.props.cookies.get('visited_books', {path: '/'});
    if (!visitedBooks) {
      visitedBooks = {};
    }

    const currentTime = parseInt(this.currentTimeValue.value);
    const selectedIndex = this.props.selectedIndex;
    const duration = parseInt(this.durationValue.value);
    if (notUndifine(selectedIndex)) {
      const audio = this.props.audios[selectedIndex];
      const bookId = this.props.bookId;
      visitedBooks = {
        ...visitedBooks, [bookId]: {
          bookId,
          bookTitle: this.props.bookTitle,
          bookUrl: this.props.bookUrl,
          selectedIndex,
          currentTime,
          duration,
          sectionNumber: audio.section_number,
          listenUrl: audio.listen_url,
          sectionTitle: audio.title,
          addedDate: new Date().getTime()
        }
      };
      let bookList = Object.values(visitedBooks);
      if (bookList.length > 4) {
        // find the oldest one to delete
        let min = bookList[0].addedDate;
        let oldestBookId = bookList[0].bookId;
        for (const [index, value] of bookList.entries()) {
          if (min > value.addedDate) {
            min = value.addedDate;
            oldestBookId = value.bookId;
          }
        }
        // delete the oldest book
        visitedBooks = _.omit(visitedBooks, oldestBookId);
      }

      this.props.cookies.set('visited_books', visitedBooks, {
        path: '/',
        expires: getOneYearDate(),
        maxAge: getOneYearInSecond()
      });
    }
  }

  loadBookmark(bookId) {
    // get from the cookie
    let visitedBooks = this.props.cookies.get('visited_books', {path: '/'});
    if (!visitedBooks) {
      visitedBooks = {};
    }
    // check the currentbook inside the book mark or not

    let histories = []
    histories = Object.values(visitedBooks);
    let markedBook = null;
    for (const [index, value] of histories.entries()) {
      if (value.bookId == bookId) {
        markedBook = histories[index];
        break;
      }
    }
    if (markedBook) {
      this.props.selectAudio(markedBook.sectionNumber, markedBook.listenUrl, markedBook.selectedIndex);
      this.props.updatePlayAction(PLAYING_ACTION_STOP);
      this.showCurrentPlayTime(markedBook.currentTime, markedBook.duration)
      this.seekControl.min = 0;
      this.seekControl.max = parseInt(markedBook.duration);
      this.seekControl.value = parseInt(markedBook.currentTime);
      this.player.currentTime = markedBook.currentTime;
    }


  }


  renderAudioList() {
    return this.props.audios.map((audio, index) => {
      return (
        <p key={audio.section_number} className="border-bottom"><i className="text-primary fa fa-play"></i>
          <button onClick={() => {
            this.props.selectAudio(audio.section_number, audio.listen_url, index)
            this.props.updatePlayAction(PLAYING_ACTION_PLAY);
          }} type="button" className={`btn btn-link${index === this.props.selectedIndex ? ' active' : ''}`}>{audio.title}</button>
        </p>
      );
    });
  }

  render() {
    let url = this.props.url;
    if (notUndifine(this.props.bookUrl)) {
      url = this.props.bookUrl.replace('images', 'images@2x');
    }
    return (

      <div className="row border shadow bg-white p-3">
        <div className="col image_player">

          <div className="row mx-1 justify-content-center">
            <Image onClick={() => this.playOrPause()} src={url}
                 className="image_cover shadow rounded-3 w-75"/>
          </div>


          <div className="row row-cols-2 mx-1 my-3 justify-content-center">
        <span className="text-left">
                            <StarRatings
                              rating={notUndifine(this.props.rating) ? this.props.rating : 0.0}
                              starDimension="19px"
                              starSpacing="0px"
                              starRatedColor="#FDCC0D"
                            />
                        </span>
            <span className="text-right">Duration: {this.props.duration}</span>
          </div>

          <div className="row row-cols-1 mx-1 my-1 justify-content-center">
            <input type="range"
                   ref={ref => this.seekControl = ref}
                   onChange={e => this.handleCurrentTimeChange(e)}/>
          </div>

          <div className="row row-cols-3 mx-1 justify-content-between">
            <span className="text-left"><label ref={ref => this.currentTime = ref}></label></span>
            <input type="hidden" ref={ref => this.currentTimeValue = ref}/>
            <span className="text-center text-muted">{this.showPlayingSpeed()}</span>
            <span className="text-right"><label ref={ref => this.duration = ref}></label></span>
            <input type="hidden" ref={ref => this.durationValue = ref}/>
          </div>

          <div className="row mx-1 mb-2 justify-content-center">
            <button type="button" className="btn btn-link btn-lg text-dark" onClick={() => {this.bookmark();}}><i className="fa fa-bookmark"></i></button>
            <label className="lead font-weight-bold text-primary mt-2">{this.showCurrentSection()}</label>
          </div>

          <div className="row row-cols-7 justify-content-center">
            <button type="button" className="btn btn-link btn-lg text-dark" onClick={() => {this.speedDown();}}>
              <i className="fa fa-backward" ></i>
            </button>
            <button type="button" className="btn btn-link btn-lg text-dark" onClick={() => this.props.updatePlayAction(PLAYING_ACTION_BACK)}>
              <i className="fa fa-fast-backward"></i>
            </button>
            <button type="button" className="btn btn-link btn-lg text-dark" onClick={() => this.props.updatePlayAction(PLAYING_ACTION_REWIND)}>
              <i className="fa fa-rotate-left"></i>
            </button>
            <button type="button" className="btn btn-link btn-lg text-dark" onClick={() => this.playOrPause()}>
              <i className="fa fa-play" ref={ref => this.playIcon = ref}></i>
            </button>
            <button type="button" className="btn btn-link btn-lg text-dark" onClick={() => this.props.updatePlayAction(PLAYING_ACTION_WIND)}>
              <i className="fa fa-rotate-right"></i>
            </button>
            <button type="button" className="btn btn-link btn-lg text-dark" onClick={() => this.props.updatePlayAction(PLAYING_ACTION_NEXT)}>
              <i className="fa fa-fast-forward"></i>
            </button>
            <button type="button" className="btn btn-link btn-lg text-dark" onClick={() => {this.speedUp();}}>
              <i className="fa fa-forward"></i>
            </button>
          </div>
          <div className="row mb-3 justify-content-center">
            <button type="button" className="btn btn-link btn-lg text-dark"><i className="fa fa-volume-off"></i>
            </button>
            <input type="range" className="form-range text-dark volume"
                   min={0}
                   max={1}
                   step={0.1}
                   onChange={e => this.handleVolumeChange(e)}
            />
            <button type="button" className="btn btn-link btn-lg text-dark"><i className="fa fa-volume-up"></i></button>
          </div>

        </div>

        <div className="col border shadow overflow-auto chapter_list">
          {this.renderAudioList()}
          <audio ref={ref => (this.player = ref)}/>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let histories = [];
  if (state.history.histories) {
    histories = Object.values(state.history.histories);
  }


  let audios = [];
  if (state.audios.audios) {
    audios = Object.values(state.audios.audios);
  }
  return {
    histories,
    audios: audios,
    selectedAudio: state.audioSelected.selectedId,
    selectedListenUrl: state.audioSelected.selectedListenUrl,
    selectedAction: state.audioSelected.selectedAction,
    selectedIndex: state.audioSelected.selectedIndex,
    selectedSpeed: state.audioSelected.selectedSpeed,
    selectedPosition: state.audioSelected.selectedPosition,
    actionCallAt: state.audioSelected.actionCallAt
  };
}

export default withCookies(connect(mapStateToProps, {
  fetchAudios,
  selectAudio,
  updatePlayAction,
  updatePlaySpeed,
  updatePosition,
  resetAudioPlayer,
  fetchHistory
})(PlayingDetail));