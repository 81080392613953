import React from "react";
import { connect } from 'react-redux';
import BookEditForm from "./BookEditForm";
import {fetchBook, fetchFailedStatus, editBook} from "../../actions";
import _ from 'lodash';
import GoogleAuth from "../GoogleAuth";


class BookEdit extends React.Component {
  componentDidMount() {
    this.props.fetchBook(this.props.match.params.id);
    this.props.fetchFailedStatus(this.props.match.params.id);


  }

  onSubmit = (fromValue) => {
    console.log('on submit', fromValue);
    this.props.editBook(this.props.match.params.id, fromValue.title, fromValue.description, fromValue.url, fromValue.failed);

  };

  render() {
    if (this.props.isAdmin) {
      return (
        <React.Fragment>
          <section className="py-1 mt-5 text-center container">
            <div className="row mt-3 border-bottom">
              <div className="col mx-auto">
                <h1 className="fw-light">Book Edit
                </h1>
              </div>
            </div>
          </section>
          <div className="bg-light">
            <div className="container my-3">
              <BookEditForm initialValues={_.assign(_.pick(this.props.book, 'title', 'description', 'url'),{failed: this.props.failed})} onSubmit={this.onSubmit}/>
            </div>
          </div>
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <p>Sign in with admin role</p>
          <GoogleAuth />
        </React.Fragment>
      );
    }

  }
}

const mapStateToProp = (state) => {

  let book = {};
  if (state.books.detail) {
    book = state.books.detail;
  }
  let failed = false;
  if (state.books.failed) {
    failed = state.books.failed;
  }

  return {
    book: book,
    isAdmin: state.auth.isAdmin,
    failed
  };
}

export default connect(mapStateToProp, {fetchBook, fetchFailedStatus, editBook})(BookEdit);