import React from 'react'
import { connect } from 'react-redux';
import {searchBook, resetSort} from "../actions";
import InfiniteScroll from "react-infinite-scroller";
import BookList from "./Books/BookList";
import CategoryList from "./Books/CategoryList";
import BookListHeader from "./Books/BookListHeader";
import {getPage} from "../utils";

class Search extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.resetSort();
  }

  loadMore(page){
    if (this.props.keyword) {
      const newPage = getPage(this.props.next);
      this.props.searchBook(this.props.keyword, newPage);
    }
  };

  handleSort() {
    if (this.props.keyword) {
      this.props.searchBook(this.props.keyword, 1);
    }
  }

  renderData() {
    if (this.props.books.length > 0)
    {
      return (
        <React.Fragment>
          <CategoryList />
          <div className="bg-light">
            <div className="container my-3">
              <BookListHeader
                count={this.props.count}
                handleShort={() => this.handleSort()}
              />
              <InfiniteScroll
                pageStart={1}
                loadMore={(page) => {this.loadMore(page)}}
                hasMore={this.props.hasNext}
                initialLoad={this.props.reset}
                loader={<div className="loader" key={0}>Loading ...</div>}>
                <BookList  books={this.props.books} />
              </InfiniteScroll>
            </div>
          </div>
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <CategoryList />
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderData()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  let books = [];
  let hasNext = false;
  let next = '';
  if (state.books.search && state.books.search.data) {
    books = state.books.search.data;
    hasNext = state.books.search.hasNext;
    next = state.books.search.next;
  }
  return {
    books: books,
    hasNext: hasNext,
    keyword: state.books.keyword,
    reset: state.books.reset,
    next: next,
    count: state.books.count
  };
}

export default connect(mapStateToProps, { searchBook, resetSort })(Search);