import _ from 'lodash';
import {
  FETCH_AUTHORS,
  FETCH_BOOK, FETCH_BOOK_GENRE,
  FETCH_BOOKS, FETCH_BOOKS_BY_AUTHOR, FETCH_FAILED_STATUS,
  FETCH_GENRES,
  FETCH_TOP_BOOKS,
  SEARCH_BOOK,
  SET_VIEWED
} from "../actions/type";


export default (state = {}, action) => {
  let hasNext = false;
  let data = [];
  switch (action.type) {
    case FETCH_BOOK:
      let detail = action.payload;
      return {...state, detail: detail}

    case FETCH_FAILED_STATUS:
      let failed = action.payload.failed;
      return {...state, failed}

    case FETCH_AUTHORS:
      let authors = [];
      authors = action.payload;
      return {... state, authors: authors}

    case FETCH_BOOK_GENRE:
      let bookgenres = [];
      bookgenres = action.payload;
      return {...state, bookgenres}

    case SET_VIEWED:
      // nothing change to the state
      return state

    case FETCH_GENRES:
      let genres = [];
      genres = action.payload;
      return {... state, books: [], topbooks: [], search: [], genres: genres, hasNext: false, next: null}

    case FETCH_BOOKS:
      let category = action.category;
      hasNext = false;
      if (action.payload.next !== null) {
        hasNext = true
      }
      if (state.books && state.books.data) {
        data = [...state.books.data]
      }
      if (state.category !== action.category) {
        // reset the data. user select order category
        data = []
      }
      if (state.sort !== action.sort) {
        data = []
      }
      if (action.reset)  {
        data = [];
      }
      data = [...data, ...action.payload.results]
      data = _.uniqBy(data, e => e.id)
      const books = {
        data: data,
        next: action.payload.next,
        hasNext
      }

      return {...state, books: books, topbooks: [], search: [], category: category, sort: action.sort, reset: action.reset, count: action.payload.count}

    case FETCH_BOOKS_BY_AUTHOR:
      let authorId = action.authorId;
      hasNext = false;
      if (action.payload.next !== null) {
        hasNext = true
      }
      if (state.books && state.books.data) {
        data = [...state.books.data]
      }
      if (state.authorId !== action.authorId) {
        // reset the data. user select order category
        data = []
      }
      if (state.sort !== action.sort) {
        data = []
      }
      if (action.reset)  {
        data = [];
      }
      data = [...data, ...action.payload.results]
      data = _.uniqBy(data, e => e.id)
      const booksByAuthor = {
        data: data,
        next: action.payload.next,
        hasNext
      }

      return {...state, books: booksByAuthor, topbooks: [], search: [], category: [], sort: action.sort, reset: action.reset, count: action.payload.count}

    case FETCH_TOP_BOOKS:
      hasNext = false;
      if (action.payload.next !== null) {
        hasNext = true
      }
      if (state.topbooks && state.topbooks.data) {
        data = [...state.topbooks.data]
      }
      if (state.sort !== action.sort) {
        data = []
      }
      if (action.reset)  {
        data = [];
      }
      data = [...data, ...action.payload.results]
      data = _.uniqBy(data, e => e.id);
      const topbooks = {
        data: data,
        hasNext,
        next: action.payload.next
      }
      return {...state, topbooks: topbooks, books: [], search: [], sort: action.sort, reset: action.reset, count: action.payload.count}
    case SEARCH_BOOK:
      let keyword = action.keyword;
      hasNext = false;
      if (action.payload.next !== null) {
        hasNext = true;
      }
      if (state.search && state.search.data) {
        data = [...state.search.data];
      }
      if (state.keyword !== action.keyword) {
        // reset the data
        data = [];
      }
      if (state.sort !== action.sort) {
        data = []
      }
      if (action.reset)  {
        data = [];
      }
      data = [...data, ...action.payload.results];
      data = _.uniqBy(data, e => e.id);
      const search = {
        data: data,
        hasNext,
        next: action.payload.next
      }
      return {...state, keyword: keyword, search: search, topbooks: [], books: [], sort: action.sort, reset: action.reset, count: action.payload.count};
    default:
      return state;

  }
};