import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class BookEditButton extends React.Component {
  render() {
    if (this.props.isAdmin) {
      return (
        <Link to={`/admin/bookedit/${this.props.bookId}`} className="btn btn-link btn-lg"><i className="fa fa-pencil"></i></Link>
      );
    }
    else {
      return null;
    }
  }
}

const mapStateToProp = (state) => {
  return {
    isAdmin: state.auth.isAdmin
  };
}

export default connect(mapStateToProp, {})(BookEditButton);