import React from "react";
import {Link} from "react-router-dom";
import Image from "./Image";
import {convertToTime, replace} from "../../utils";

const BookHistory = ({id, title, url, chapter, currentTime, duration, deleteAction}) => {
  const titleUrl = replace(title);
  return (
    <div className="card_item my-3 mx-1 shadow bg-white">
      <div className="card border-0">
        <div className="card-header text-center border-0"><Image src={url} className="shadow w-100"/>
        </div>
        <div className="card-body">
          <p className="card-text"><Link to={`/detail/${id}/${titleUrl}`}>{title}</Link></p>
          <p className="card-text">{chapter}</p>
          <div className="row row-cols-1 mx-1 my-1 justify-content-center">
            <input readOnly={true} type="range" min="0" value={currentTime} max={duration} />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <small className="text-muted">{convertToTime(currentTime)} / {convertToTime(duration)}</small>
            <button type="button" onClick={() => {deleteAction()}} className="btn btn-link btn-lg text-danger"><i className="fa fa-trash-o"></i></button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookHistory;