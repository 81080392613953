import React from "react";
import BookGenreItem from "./BookGenreItem";

const BookGenreList = ({bookgenres}) => {

  const renderBookGenreList = (bookgenres) => {
    return bookgenres.map(bookgenre => {
      return (
        <BookGenreItem
          key={bookgenre.id}
          id={bookgenre.id}
          name={bookgenre.name}
        />
      );
    });
  }

  return (
    <div className="row row-cols-1 border shadow bg-white p-3 my-3 text-justify summary">
      <h5>Genres:</h5>
      <div>
        {renderBookGenreList(bookgenres)}
      </div>
    </div>
  );
}

export default BookGenreList;