import React from "react";
import {Field, reduxForm} from "redux-form";

class BookEditForm extends React.Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title</label>
          <Field name="title" className="form-control" component="input" type="text" />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">Description</label>
          <Field name="description" className="form-control" value={this.props.description} component="textarea" />
        </div>
        <div className="mb-3">
          <label htmlFor="url" className="form-label">Url</label>
          <Field name="url" className="form-control" value={this.props.url} component="input" type="text" />
        </div>
        <div className="form-check">
          <Field className="form-check-input" name="failed" component="input" type="checkbox" />
          <label className="form-check-label">
            Failed
          </label>
        </div>
        <br/>
        <button type="Submit" className="btn btn-primary">Save</button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'bookEditForm',
  enableReinitialize : true
})(BookEditForm);