export const FETCH_BOOKS = 'FETCH_BOOKS';
export const FETCH_TOP_BOOKS = 'FETCH_TOP_BOOKS';
export const FETCH_BOOK = 'FETCH_BOOK';
export const FETCH_FAILED_STATUS = 'FETCH_FAILED_STATUS';
export const FETCH_BOOKS_BY_AUTHOR = 'FETCH_BOOKS_BY_AUTHOR';
export const SEARCH_BOOK = 'SEARCH_BOOK';

export const SORT_HISTORY_TITLE = 'SORT_HISTORY_TITLE';
export const SORT_HISTORY_TITLE_REV = 'SORT_HISTORY_TITLE_REV';
export const SORT_HISTORY_AUTHOR = 'SORT_HISTORY_AUTHOR';
export const SORT_HISTORY_AUTHOR_REV = 'SORT_HISTORY_AUTHOR_REV';
export const SORT_HISTORY_ADDED = 'SORT_HISTORY_ADDED';
export const SORT_HISTORY_ADDED_REV = 'SORT_HISTORY_ADDED_REV';
export const FETCH_HISTORY = 'FETCH_HISTORY';

export const FETCH_BOOK_GENRE = 'FETCH_BOOK_GENRE';

export const FETCH_AUTHORS = 'FETCH_AUTHORS';
export const FETCH_GENRES = 'FETCH_GENRES';
export const SET_VIEWED = 'SET_VIEWED';

export const SET_SORT = 'SET_SORT';
export const RESET_SORT = 'RESET_SORT';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

export const ADMIN_SIGN_IN = 'ADMIN_SIGN_IN';
export const ADMIN_SIGN_OUT = 'ADMIN_SIGN_OUT';

export const FETCH_AUDIOS = 'FETCH_AUDIOS';
export const SELECT_AUDIO = 'SELECT_AUDIO';

export const UPDATE_PLAYING_ACTION = 'UPDATE_PLAYING_ACTION';
export const UPDATE_PLAYING_SPEED = 'UPDATE_PLAYING_SPEED';
export const UPDATE_POSITION = 'UPDATE_POSITION';
export const UPDATE_VOLUME = 'UPDATE_VOLUME';


// status for audio playing
export const PLAYING_ACTION_PLAY = 'PLAYING_ACTION_PLAY';
export const PLAYING_ACTION_STOP = 'PLAYING_ACTION_STOP';
export const PLAYING_ACTION_NEXT = 'PLAYING_ACTION_NEXT';
export const PLAYING_ACTION_BACK = 'PLAYING_ACTION_BACK';
export const PLAYING_ACTION_WIND = 'PLAYING_ACTION_WIND';
export const PLAYING_ACTION_REWIND = 'PLAYING_ACTION_REWIND';
export const PLAYING_RESET = 'PLAYING_RESET';