import axios from 'axios';

/*
export default axios.create({
  //baseURL: 'http://192.168.1.167:8080/api',
  baseURL: 'https://dev-proxy.booksfella.site/api',
});
*/


export default axios.create({
  //baseURL: 'http://192.168.1.167:8080/api',
  baseURL: 'https://dev-api.booksfella.site',
  auth: {
    username: 'admin',
    password: 'admin'
  }
});

