import _ from 'lodash';
import {
  FETCH_HISTORY, SORT_HISTORY_ADDED, SORT_HISTORY_ADDED_REV,
  SORT_HISTORY_TITLE,
  SORT_HISTORY_TITLE_REV
} from "../actions/type";

 const handle = (state = {histories: []}, action) => {
  let histories = [];
  switch (action.type) {
    case FETCH_HISTORY:
      histories = action.payload;
      return {...state, histories};
    case SORT_HISTORY_TITLE:
      histories = [...state.histories];
      histories.sort((a, b) =>  {
        const titleA = a.bookTitle.toUpperCase();
        const titleB = b.bookTitle.toUpperCase();
        if (titleA > titleB) {
          return 1
        }
        if (titleA < titleB) {
          return -1
        }
        return 0
      });
      return {...state, histories}

    case SORT_HISTORY_TITLE_REV:
      histories = [...state.histories];
      histories.sort((a, b) =>  {
        const titleA = a.bookTitle.toUpperCase();
        const titleB = b.bookTitle.toUpperCase();
        if (titleB > titleA) {
          return 1
        }
        if (titleB < titleA) {
          return -1
        }
        return 0
      });
      return {...state, histories}

    case SORT_HISTORY_ADDED:
      histories = [...state.histories];
      histories.sort((a, b) =>  {
        const addedDateA = a.addedDate;
        const addedDateB = b.addedDate;
        if (addedDateA > addedDateB) {
          return 1
        }
        if (addedDateA < addedDateB) {
          return -1
        }
        return 0
      });
      return {...state, histories}

    case SORT_HISTORY_ADDED_REV:
      histories = [...state.histories];
      histories.sort((a, b) =>  {
        const addedDateA = a.addedDate;
        const addedDateB = b.addedDate;
        if (addedDateB > addedDateA) {
          return 1
        }
        if (addedDateB < addedDateA) {
          return -1
        }
        return 0
      });
      return {...state, histories}
    default:
      return state;
  }
};

 export default handle;