import React from 'react';
import GoogleAuth from "./components/GoogleAuth";
import {Link} from "react-router-dom";
import SearchForm from "./components/Comments/SearchForm";

const Header = () => {
  return (
    <header>

      <nav className="navbar fixed-top navbar-expand-lg navbar-light nav_background">
        <Link className="navbar-brand" to="/"><i className="fa fa-book"></i></Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/genres/">Genres</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/history/">History</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/privacy/">Privacy Policy</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/copyrights/">Copyrights</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/sources/">Sources</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact/">Contact</Link>
            </li>
          </ul>
          <SearchForm />
        </div>
      </nav>
    </header>
  );
};

export default Header;