import React from 'react';
import ReactGA from 'react-ga';
import {Router, Route, Switch} from 'react-router-dom';

import Home from './Home';
import Header from '../Header';
import Footer from '../Footer';
import history from '../history';
import Detail from "./Detail";
import Category from "./Category";
import Review from "./Review";
import Search from "./Search";
import History from "./History";
import Genres from "./Genres";
import Privacy from "./Privacy";
import Copyrights from "./Copyrights";
import Source from "./Source";
import Contact from "./Contact";
import Author from "./Author";
import AdminLogin from "./Admin/AdminLogin";
import BookEdit from "./Admin/BookEdit";


history.listen((location, action) => {
  // reset the scroll position
  window.scrollTo(0, 0)
  // put the information for google analysis
  console.log('path name', location.pathname);
  ReactGA.initialize('UA-189967748-1');
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
  if (!location.pathname.includes('detail')) {
    document.title = 'You book reader';
  }
})

const App = () => {
  return (
    <React.Fragment>
      <Router history={history}>
        <Header/>
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/category/:id/:name" exact component={Category}/>
          <Route path="/author/:id/:name" exact component={Author}/>
          <Route path="/detail/:id/:title" exact component={Detail}/>
          <Route path="/search/" exact component={Search}/>
          <Route path="/review/" exact component={Review}/>
          <Route path="/history/" exact component={History}/>
          <Route path="/genres/" exact component={Genres}/>
          <Route path="/privacy/" exact component={Privacy}/>
          <Route path="/copyrights/" exact component={Copyrights}/>
          <Route path="/sources/" exact component={Source}/>
          <Route path="/contact/" exact component={Contact}/>
          <Route path="/admin/adminlogin/" exact component={AdminLogin}/>
          <Route path="/admin/bookedit/:id" exact component={BookEdit}/>
        </Switch>
        <Footer/>
      </Router>
    </React.Fragment>
  );
};


export default App;

