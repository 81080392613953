import React from "react";
import {Link} from "react-router-dom";
import GenreItem from "./GenreItem";

const GenresList = ({genres}) => {
  return genres.map(genre => {
    return (
      <GenreItem
        key={genre.id}
        id={genre.id}
        name={genre.name}
        count={genre.num_of_book}/>
    );
  })
}

export default GenresList;