import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from "redux-form";
import {searchBook} from "../../actions";
import history from '../../history';

class SearchForm extends React.Component {
  onSubmit = (formValues) => {
    if (formValues.search) {
      this.props.searchBook(formValues.search, 1, true);
      history.push('/search/');
    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <form className="form-inline my-2 my-lg-0"  onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field name="search" component="input" className="form-control mr-sm-2" type="search" style={{width: 380}} placeholder="Search"
                 aria-label="Search"/>
          <button type="submit" className="input-group-text amber lighten-3" id="basic-text1"><i
            className="fa fa-search text-grey"
            aria-hidden="true"></i></button>
      </form>
    );
  }
}

export default connect(null, {searchBook})(reduxForm({
  form: 'searchForm'
})(SearchForm));