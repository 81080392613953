import _ from 'lodash';
import {FETCH_AUDIOS} from "../actions/type";


export default (state = {}, action) => {
  let audios = {}
  switch (action.type) {
    case FETCH_AUDIOS:
      audios = {...audios, ..._.mapKeys(action.payload, 'section_number')}
      return {...state, audios};
    default:
      return state;
  }
};