import React from "react";

const Footer = () => {
  return (
    <footer className="text-muted py-5">
      <div className="container border-top">
        <p className="float-right my-2">

        </p>
        <p className="my-2">Youbookreader is &copy; NHS group!</p>
      </div>
    </footer>
  );
}

export default Footer;