import React from "react";
import {connect} from 'react-redux';
import {setSort} from "../../actions";

class BookListHeader extends React.Component{
  buttonClick(field) {
    this.props.setSort(field);
    this.props.handleShort();
  }

  render() {
    return(
      <div className="row py-1 border-bottom">
        <div className="col">
          <span className="lead fw-light">{this.props.count} results</span>
        </div>
        <div className="row row-cols-8 lead ml-2">
          <button type="button" className="btn btn-link btn-lg" onClick={() => this.buttonClick('title')}><i className="text-primary fa fa-sort mx-1"></i>Title
          </button>
          <button type="button" className="btn btn-link btn-lg" onClick={() => this.buttonClick('auth_name')}><i className="text-primary fa fa-sort mx-1"></i>Author
          </button>
          <button type="button" className="btn btn-link btn-lg" onClick={() => this.buttonClick('rating')}><i className="text-primary fa fa-sort mx-1"></i>Rating
          </button>
          <button type="button" className="btn btn-link btn-lg" onClick={() => this.buttonClick('total_time_secs')}><i className="text-primary fa fa-sort mx-1"></i>Duration
          </button>
        </div>
      </div>
    );
  }
}


export default connect(null, {setSort})(BookListHeader);