import React from "react";
import {Link} from "react-router-dom";

const GenresHeader = () => {
  return (
    <section className="py-1 mt-5 text-center container">
      <div className="row mt-3 border-bottom">
        <div className="col mx-auto">
          <h1 className="fw-light">All genres</h1>
          <p className="lead text-muted">There's something for everybody</p>
        </div>
      </div>
    </section>
  );
};

export default GenresHeader;