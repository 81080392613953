import React from "react";

class Image extends React.Component {
  addDefaultSrc(ev){
    ev.target.src=process.env.PUBLIC_URL + '/default_book_cover.jpg'
  }

  render() {
    return(
      <img
        {...this.props}
        onError={this.addDefaultSrc}
        src={`https://youbookreader.com/${this.props.src}`}/>
    );
  }
}

export default Image;