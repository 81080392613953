import React from 'react';
import { connect } from 'react-redux';
import StarRatings from "react-star-ratings";
import ReviewForm from './Comments/ReviewForm'

class Review extends React.Component {
  onSubmit = (formValue) => {
    console.log('formValue', formValue);
  }

  render() {
    return (
      <ReviewForm  initialValues={{comment: "this is my comment", rating: 3.5}} onSubmit={this.onSubmit}/>

    );
  }
}

export default connect(null, {})(Review);