import React from 'react';
import {connect} from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';

import {fetchBooks, fetchBooksByAuthor, resetSort} from '../actions';
import CategoryList from "./Books/CategoryList";
import BookListHeader from "./Books/BookListHeader";
import BookList from "./Books/BookList";
import {getPage} from "../utils";

class Author extends React.Component {
  componentDidMount() {
    console.log('component mount');
    this.props.resetSort();
    this.props.fetchBooksByAuthor(1, this.props.authorId, true);
  }

  loadMore(page){
    console.log('load more');
    const newPage = getPage(this.props.next);
    this.props.fetchBooksByAuthor(newPage, this.props.authorId);
  };

  handleSort() {
    this.props.fetchBooksByAuthor(1, this.props.authorId);
  }

  render() {
    if (this.props.books.length === 0)
    {
      <React.Fragment>
        <CategoryList/>
        <div className="bg-light">
          <div className="container my-3">
            <BookListHeader
              count={this.props.count}
              handleShort={() => this.handleSort()}/>
          </div>
        </div>
      </React.Fragment>
    }
    return (
      <React.Fragment>
        <CategoryList/>
        <div className="bg-light">
          <div className="container my-3">
            <BookListHeader
              count={this.props.count}
              handleShort={() => this.handleSort()}/>
            <InfiniteScroll
              pageStart={1}
              loadMore={(page) => {this.loadMore(page)}}
              hasMore={this.props.hasNext}
              loader={<div className="loader" key={0}>Loading ...</div>}>
              <BookList  books={this.props.books} />
            </InfiniteScroll>
          </div>
        </div>
      </React.Fragment>


      //<BookGrid  books={this.props.books} />

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let authorId = ownProps.match.params.id;
  let books = [];
  let hasNext = false;
  let next = '';
  if (state.books.books && state.books.books.data) {
    books = state.books.books.data;
    hasNext = state.books.books.hasNext;
    next = state.books.books.next;
  }
  return {
    books: books,
    authorId,
    hasNext: hasNext,
    next: next,
    count: state.books.count
  };
};

export default connect(mapStateToProps, { fetchBooksByAuthor, resetSort })(Author);