import {RESET_SORT, SET_SORT} from "../actions/type";
import {SORT_DEFAULT} from "../commons";

export default (state = {sort: {name:SORT_DEFAULT, isAsc: true}}, action) => {
  const newState = {...state};
  switch (action.type) {
    case SET_SORT:
      if (newState.sort.name === action.payload) {
        newState.sort.isAsc = !newState.sort.isAsc
      }
      else {
        newState.sort.name = action.payload;
        newState.sort.isAsc = true;
      }
      return newState;
    case RESET_SORT:
      newState.sort.name = SORT_DEFAULT;
      newState.sort.isAsc = true;
      return newState
    default:
      return state;
  }
};