import React from "react";
import BookItem from "./BookItem";

const BookList = ({books}) => {
  const renderList = (books) => {
    return books.map(book => {
      let rating = 0;
      if (book.rating) {
        rating = book.rating
      }
      return (
        <BookItem
          key={book.id}
          id={book.id}
          title={book.title}
          author={book.auth_name}
          rating={rating}
          url={book.url}
          duration={book.total_time}
        />
      );
    });
  }
  return (
      <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 justify-content-center justify-content-xl-start">
        {renderList(books)}
      </div>
    );
};

export default BookList;