import React from "react";
import {Link} from "react-router-dom";

const CategoryList = () => {
  return (
    <section className="py-1 mt-5 text-center container">
      <div className="row mt-3 border-bottom">
        <div className="col mx-auto">
          <h1 className="fw-light">Explore our collection</h1>
          <p className="lead text-muted">There's something for everybody</p>
          <p>
            <Link to='/' className="btn btn-outline-primary my-2 mr-1 ml-1"><i
              className="text-warning fa fa-star mx-1"></i>TOP 100
            </Link>
            <Link to="/category/6" className="btn btn-outline-primary my-2 mr-1 ml-1">Kids</Link>
            <Link to="/category/4" className="btn btn-outline-primary my-2 mr-1 ml-1">Fiction</Link>
            <Link to="/category/22" className="btn btn-outline-primary my-2 mr-1 ml-1">Fantasy</Link>
            <Link to="/category/20" className="btn btn-outline-primary my-2 mr-1 ml-1">Mystery</Link>
            <Link to="/category/29" className="btn btn-outline-primary my-2 mr-1 ml-1">History</Link>
            <Link to="/category/71" className="btn btn-outline-primary my-2 mr-1 ml-1">Adventure</Link>
            <Link to="/category/37" className="btn btn-outline-primary my-2 mr-1 ml-1">Romance</Link>
            <Link to="/category/16" className="btn btn-outline-primary my-2 mr-1 ml-1">Non-fiction</Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default CategoryList;