import React from "react";

const Copyrights = () => {
  return (
    <React.Fragment>
      <section className="py-1 mt-5 text-center container">
        <div className="row mt-3 border-bottom">
          <div className="col mx-auto">
            <h1 className="fw-light">Copyrights
            </h1>
          </div>
        </div>
      </section>
      <div className="bg-light">
        <div className="container my-3">
          <p>
            All the materials in YouBookReader are in the public domain according to the Copyright law of the United
            States, which means they do not belong to any party of interest. If you found any copyrighted material
            mistakenly uploaded on YouBookReader, please contact us.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Copyrights;