import React from "react";

const Contact = () => {
  return (
    <React.Fragment>
      <section className="py-1 mt-5 text-center container">
        <div className="row mt-3 border-bottom">
          <div className="col mx-auto">
            <h1 className="fw-light">Contact
            </h1>
          </div>
        </div>
      </section>
      <div className="bg-light">
        <div className="container my-3">
          <p>
            For support or any questions:
            <br/>
            Email us at: <a href="mailto:admin@youbookreader.com">admin@youbookreader.com</a>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Contact;