import book from '../apis/books';
import {
  ADMIN_SIGN_IN,
  ADMIN_SIGN_OUT,
  FETCH_AUDIOS,
  FETCH_AUTHORS,
  FETCH_BOOK,
  FETCH_BOOK_BY_AUTHOR,
  FETCH_BOOK_GENRE,
  FETCH_BOOKS,
  FETCH_BOOKS_BY_AUTHOR, FETCH_FAILED_STATUS,
  FETCH_GENRES,
  FETCH_HISTORY,
  FETCH_TOP_BOOKS,
  PLAYING_RESET,
  RESET_SORT,
  SEARCH_BOOK,
  SELECT_AUDIO,
  SET_SORT,
  SET_VIEWED,
  SIGN_IN,
  SIGN_OUT,
  UPDATE_PLAYING_ACTION,
  UPDATE_PLAYING_SPEED,
  UPDATE_POSITION,
  UPDATE_VOLUME
} from "./type";
import {SORT_DEFAULT} from "../commons";

export const fetchBooks = (page, genId, reset=false) => async (dispatch, getState) => {
  let sort = getState().sort.sort.name;
  if (!getState().sort.sort.isAsc)
  {
    sort = '-' + sort;
  }
  let ordering = '&ordering='+sort;
  if (getState().sort.sort.name === SORT_DEFAULT) {
    ordering = ''
  }
  const response = await book.get(`/books/?page=${page}&gen_id=${genId}${ordering}`);
  dispatch({
    type: FETCH_BOOKS,
    payload: response.data,
    category: genId,
    sort: sort,
    reset
  });
};


export const fetchBooksByAuthor = (page, authorId, reset=false) => async (dispatch, getState) => {
  let sort = getState().sort.sort.name;
  if (!getState().sort.sort.isAsc)
  {
    sort = '-' + sort;
  }
  let ordering = '&ordering='+sort;
  if (getState().sort.sort.name === SORT_DEFAULT) {
    ordering = ''
  }
  const response = await book.get(`/booksbyauthor/?page=${page}&author_id=${authorId}${ordering}`);
  dispatch({
    type: FETCH_BOOKS_BY_AUTHOR,
    payload: response.data,
    category: authorId,
    sort: sort,
    reset
  });
};

export const fetchTopBooks = (page, reset=false) => async (dispatch, getState) => {
  let sort = getState().sort.sort.name;
  if (!getState().sort.sort.isAsc)
  {
    sort = '-' + sort;
  }
  let ordering = '&ordering='+sort;
  if (getState().sort.sort.name === SORT_DEFAULT) {
    ordering = ''
  }
  const response = await book.get(`/topbooks/?page=${page}${ordering}`);
  dispatch({
    type: FETCH_TOP_BOOKS,
    payload: response.data,
    sort: sort,
    reset
  });
};

export const fetchBook = (id) => async (dispatch) => {
  const response = await book.get(`/bookdetail/${id}/`);
  dispatch({
    type: FETCH_BOOK,
    payload: response.data
  });
};

export const searchBook = (keyword, page, reset = false) => async (dispatch, getState) => {
  let sort = getState().sort.sort.name;
  if (!getState().sort.sort.isAsc)
  {
    sort = '-' + sort;
  }
  let ordering = '&ordering='+sort;
  if (getState().sort.sort.name === SORT_DEFAULT) {
    ordering = ''
  }
  const response = await book.get(`/booksearch/?search=${keyword}&page=${page}${ordering}`);
  dispatch({
    type: SEARCH_BOOK,
    payload: response.data,
    keyword,
    sort,
    reset
  });
};

export const selectAudio = (audioId, listenUrl, index) => {
  return {
    type: SELECT_AUDIO,
    payload: {
      audioId: audioId,
      listenUrl: listenUrl,
      index
    }
  };
}

export const fetchHistory = (data) => {
  return {
    type: FETCH_HISTORY,
    payload: data
  };
}

export const sortHistory = (type) => {
  return {
    type: type
  };
}

export const updatePlayAction = (action) => {
  return {
    type: UPDATE_PLAYING_ACTION,
    payload: {
      action
    }
  };
}


export const updatePlaySpeed = (speed) => {
  return {
    type: UPDATE_PLAYING_SPEED,
    payload: {
      speed
    }
  };
}

export const updatePosition = (position) => {
  return {
    type: UPDATE_POSITION,
    payload: {
      position
    }
  };
}

export const updateVolume = (volume) => {
  return {
    type: UPDATE_VOLUME,
    payload: {
      volume
    }
  };
}

export const resetAudioPlayer = () => {
  return {
    type: PLAYING_RESET
  };
}

export const fetchAudios = (bookId) => async dispatch =>{
  const response = await book.get(`/audio/?book_id=${bookId}`);
  dispatch({
    type: FETCH_AUDIOS,
    payload: response.data
  });
}

export const fetchBookGenre = (bookId) => async dispatch =>{
  const response = await book.get(`/bookgenre/?book_id=${bookId}`);
  dispatch({
    type: FETCH_BOOK_GENRE,
    payload: response.data
  });
}

export const fetchGenres = () => async dispatch =>{
  const response = await book.get('/genresview/');
  dispatch({
    type: FETCH_GENRES,
    payload: response.data
  });
}

export const fetchAuthors = (bookId) => async dispatch =>{
  const response = await book.get(`/author/?book_id=${bookId}`);
  dispatch({
    type: FETCH_AUTHORS,
    payload: response.data
  });
}

export const setViewed = (bookId) => async dispatch =>{
  const response = await book.post('/books_view_count/', {
    book_id: bookId
  });
  dispatch({
    type: SET_VIEWED,
  });
}

export const adminLogin = (username, password) => async dispatch => {
  const response = await book.post('/api-token-auth/', {
    username: username,
    password: password
  })
  dispatch({
    type: ADMIN_SIGN_IN,
    payload: response.data
  });
}

export const editBook = (id, title, description, url, failed) => async dispatch => {
  let hasError = false;
  const response1 = await book.put(`/books_admin/?book_id=${id}`, {
    id: id,
    title: title,
    description: description,
    url_book_cover_path: url
  })
  console.log('failed', failed);
  if (failed) {
    await book.post(`/books_failed_admin/?book_id=${id}`, {
      id: id
    })
  }
  else {
    await book.delete(`/books_failed_admin/?book_id=${id}`)
  }

}

export const adminLogout = () => {
  return {
    type: ADMIN_SIGN_OUT,
  };
}

// Sign in
export const signIn = (userId, firstName, lastName, email) => async dispatch => {
  const userResponse = await book.get(`/users/?user_id=${userId}`);
  if (userResponse.data.count === 0) {
    // write user to the system
    await book.post('/users/', {
      user_id: userId,
      first_name: firstName,
      last_name: lastName,
      email: email
    })
  }
  let user_role = '';
  if (userResponse.data.count > 0)
  {
    user_role = userResponse.data.results[0].user_role;
  }
  // send the data to the reducer
  dispatch({
    type: SIGN_IN,
    payload: {
      userId,
      firstName,
      lastName,
      email,
      user_role
    }
  });
};

// Sign out
export const signOut = () => {
  return {
    type: SIGN_OUT
  };
};

export const fetchFailedStatus = (bookId) => async dispatch => {
  const result = await book.get(`/books_failed_admin/?book_id=${bookId}`);
  let failed = false;
  if (result.status === 200) {
    failed = true
  }
  console.log('result', result);
  dispatch({
    type: FETCH_FAILED_STATUS,
    payload: {
      failed
    }
  });
}

// sort
export const setSort = (field) => {
  return {
    type: SET_SORT,
    payload: field
  };
}

export const resetSort = () => {
  return {
    type: RESET_SORT
  };
}