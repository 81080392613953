import React from "react";
import {connect} from 'react-redux';
import {sortHistory, setSort} from "../../actions";
import {
  SORT_HISTORY_ADDED,
  SORT_HISTORY_ADDED_REV,
  SORT_HISTORY_TITLE,
  SORT_HISTORY_TITLE_REV
} from "../../actions/type";

class HistoryListHeader extends React.Component{
  handleSort(field) {
    this.props.setSort(field);
    switch (this.props.sort.name) {
      case 'title':
        if (this.props.sort.isAsc) {
          this.props.sortHistory(SORT_HISTORY_TITLE)
        } else {
          this.props.sortHistory(SORT_HISTORY_TITLE_REV)
        }
        return;
      case 'addedDate':
        if (this.props.sort.isAsc) {
          this.props.sortHistory(SORT_HISTORY_ADDED)
        } else {
          this.props.sortHistory(SORT_HISTORY_ADDED_REV)
        }
      default:
        return
    }
  }

  render() {
    return(
      <div className="row py-1 border-bottom">
        <div className="col">
          <span className="lead fw-light">{this.props.count} results</span>
        </div>
        <div className="row row-cols-8 lead ml-2">
          <button type="button" className="btn btn-link btn-lg" onClick={() => this.handleSort('title')}><i className="text-primary fa fa-sort mx-1"></i>Title
          </button>
          <button type="button" className="btn btn-link btn-lg" onClick={() => this.handleSort('addedDate')}><i className="text-primary fa fa-sort mx-1"></i>Last View
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sort: state.sort.sort
  };
}


export default connect(mapStateToProps, {sortHistory, setSort})(HistoryListHeader);