import React from "react";
import {Link} from "react-router-dom";
import {replace} from "../../utils";

const TitleDetail = ({title, authors}) => {
  const renderAuthor = (authors) => {
    if (authors) {
      return authors.map(author => {
        const nameUrl = replace(author.last_name);
        return (
          <p key={author.id} className="lead text-muted">By: <Link to={`/author/${author.id}/${nameUrl}`}
                                                className="btn-link mx-1">{author.first_name} {author.last_name}</Link>({author.dob}-{author.dod})</p>
        );
      })
    }
    return null;
  }

  return (
    <section className="py-1 text-center container">
      <div className="row mt-3 border-bottom">
        <div className="col mx-auto">
          <h1 className="fw-light">{title}</h1>
          {renderAuthor(authors)}
        </div>
      </div>
    </section>
  );
}

export default TitleDetail;