import {SIGN_IN, SIGN_OUT} from '../actions/type';

const INTIAL_STATE = {
  isSignIn: null,
  userId: null
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      let isAdmin = false;
      if (action.payload.user_role === 'ADMIN') {
        isAdmin = true;
      }
      return {
        ...state,
        isSignIn: true,
        isAdmin: isAdmin,
        userId: action.payload.userId,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        userRole: action.payload.user_role
      };
    case SIGN_OUT:
      return {...state, isSignIn: false, isAdmin: false, userId: null};
    default:
      return state;
  }
};