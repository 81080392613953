import React from 'react';
import {connect} from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';

import { fetchTopBooks, resetSort } from '../actions';
import BookList from "./Books/BookList";
import BookListHeader from "./Books/BookListHeader";
import CategoryList from "./Books/CategoryList";
import {getPage} from "../utils";

class Home extends React.Component {
  componentDidMount() {
    this.props.resetSort();
    this.props.fetchTopBooks(1, true);
  }

  loadMore(page){
    const newPage = getPage(this.props.next);
    this.props.fetchTopBooks(newPage);
  };

  handleSort() {
    this.props.fetchTopBooks(1);
  }

  render() {
    if (this.props.books.length === 0)
    {
      return (
        <React.Fragment>
          <CategoryList/>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <CategoryList/>
        <div className="bg-light">
          <div className="container my-3">
            <BookListHeader
              count={this.props.count}
              handleShort={() => this.handleSort()}/>
            <InfiniteScroll
              pageStart={1}
              loadMore={(page) => {this.loadMore(page)}}
              hasMore={this.props.hasNext}
              loader={<div className="loader" key={0}>Loading ...</div>}>
              <BookList  books={this.props.books} />
            </InfiniteScroll>
          </div>
        </div>
      </React.Fragment>


      //<BookGrid  books={this.props.books} />

    );
  }

}

const mapStateToProps = (state) => {
  let books = [];
  let hasNext = false;
  let next = '';
  if (state.books.topbooks && state.books.topbooks.data) {
    books = state.books.topbooks.data;
    hasNext = state.books.topbooks.hasNext;
    next = state.books.topbooks.next;
  }
  return {
    books: books,
    hasNext: hasNext,
    reset: state.books.reset,
    next: next,
    count: state.books.count

  };
};

export default connect(mapStateToProps, { fetchTopBooks, resetSort })(Home);