import {
  PLAYING_RESET,
  SELECT_AUDIO,
  UPDATE_PLAYING_ACTION,
  UPDATE_PLAYING_SPEED,
  UPDATE_POSITION
} from "../actions/type";


export default (state = {}, action) => {
  switch (action.type) {
    case PLAYING_RESET:
      return {};
    case SELECT_AUDIO:
      return {...state, selectedId : action.payload.audioId, selectedListenUrl: action.payload.listenUrl, selectedIndex: action.payload.index};
    case UPDATE_PLAYING_ACTION:
      return {...state, selectedAction: action.payload.action, actionCallAt: new Date().getTime()};
    case UPDATE_PLAYING_SPEED:
      return {...state, selectedSpeed: action.payload.speed};
    case UPDATE_POSITION:
      return {...state, selectedPosition: action.payload.position};
    default:
      return state;
  }
};