import React from "react";

const Privacy = () => {
  return (
    <React.Fragment>
      <section className="py-1 mt-5 text-center container">
        <div className="row mt-3 border-bottom">
          <div className="col mx-auto">
            <h1 className="fw-light">Privacy Policy
            </h1>
          </div>
        </div>
      </section>
      <div className="bg-light">
        <div className="container my-3">
          <p>
            YouBookReader collects reviews and ratings from users, uses cookies to track their preference and also
            maintains standard server logs. This information is not shared outside the website.
          </p>
          <p>
            YouBookReader may employ third party advertisers like Google to cover the operational cost for our free
            service. Google uses a tracking cookie as well as third party cookies like DoubleClick which enable Google
            and their partners to serve ads based on your visit to this site and other sites. Besides, some features of
            Google Analytics are embedded into YouBookReader in order to improve our service.
          </p>
        </div>
      </div>
    </React.Fragment>
  )
    ;
}

export default Privacy;