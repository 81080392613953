import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux'
import {withCookies} from 'react-cookie';
import {fetchHistory, setSort} from "../actions";
import HistoryList from "./Books/HistoryList";
import HistoryTitle from "./Books/HistoryTitle";
import HistoryListHeader from "./Books/HistoryListHeader";
import CategoryList from "./Books/CategoryList";

class History extends React.Component {

  loadBookmark() {
    // get from the cookie
    let visitedBooks = this.props.cookies.get('visited_books', {path: '/'});
    if (!visitedBooks) {
      visitedBooks = {};
    }
    console.log('load history', visitedBooks);
    this.props.fetchHistory(Object.values(visitedBooks));
  }

  deleteBookmark(id) {
    let visitedBooks = this.props.cookies.get('visited_books', {path: '/'});
    if (!visitedBooks) {
      visitedBooks = {};
    }
    visitedBooks = _.omit(visitedBooks, id);
    // save the book back
    this.props.cookies.set('visited_books', visitedBooks, {
      path: '/'
    });
    this.props.fetchHistory(Object.values(visitedBooks));
  }

  componentDidMount() {
    this.props.setSort('title');
    this.loadBookmark();
  }


  render() {
    if (this.props.histories) {
      return (
        <React.Fragment>
          <CategoryList/>
          <div className="bg-light">
            <div className="container">
              <HistoryListHeader
                count={this.props.count}
              />
              <HistoryList
                handleDelete={id => this.deleteBookmark(id)}
                histories={this.props.histories}/>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return <div>Loading ...</div>

  }
}

const mapStateToProps = (state) => {
  let histories = [];
  if (state.history.histories) {
    histories = state.history.histories;
  }
  return {
    histories,
    count: histories.length
  };
}

export default withCookies(connect(mapStateToProps, {fetchHistory, setSort})(History));