import React from "react";
import './Summary.css';

const Summary = ({description}) => {
  return (
    <div id="module" className="row row-cols-1 border shadow bg-white p-3 my-3 text-justify summary">
      <h5>Summary:</h5>
      <p class="collapse" id="collapseExample" aria-expanded="false" dangerouslySetInnerHTML={{__html: description}}></p>
      <a role="button" className="collapsed" data-toggle="collapse" href="#collapseExample" aria-expanded="false"
         aria-controls="collapseExample">
      </a>
    </div>
  );
}

export default Summary;